import React from 'react';
import PropTypes from 'prop-types';

import {PageWrapper} from '../components/templates';
import {Divider} from '../components/common';
import LayoutGenerator from '../helpers/layoutGenerator';
import {Hero} from '../components/organisms';

/**
 * @description Page template for 'species' page.
 * @param {object} pageContext Page data from gatsby queries.
 * @return {DOMElement} Species page React element.
 */
const Species = ({pageContext}) => {
  return (
    <PageWrapper pageContext={pageContext}>
      <Hero
        title={pageContext.title}
        summary={pageContext.summary.summary}
        imageData={pageContext.image}/>
      <Divider additionalClass="rc-margin-bottom--xl--mobile rc-margin-bottom--lg--desktop"/>
      <div className="rc-padding-bottom--md rc-padding-bottom--xs--desktop"></div>
      <LayoutGenerator layouts={pageContext.layoutContainer} />
    </PageWrapper>
  );
};

Species.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Species;
